/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .App {
    width: 100vw;
    .page-header {
      @supports (-webkit-appearance:none) {
        .os-android & {
          min-height: calc(100vh - 56px);
        }
      }
    }
    font-family: 'Be Vietnam', sans-serif;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .title {
    position: absolute;
    width: 50vw;
    top: 15vh;
    left: 22vw;
    padding: 10px;
    border: 3px black solid;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
  }
  .title:hover {
    border: 3px #222 solid;
    background-color: #222;
  }

  /* #poorreuseofcode */
  .button {
    position: absolute;
    width: 22vw;
    left: 36vw;
    padding: 10px;
    border: 3px black solid;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: 'Be Vietnam', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
    background-color: #FFF0;
  }

  .button:hover {
    cursor: pointer;
  }

  .button:active {
    background-color: #0002;
  }

  .App-link {
    color: #61dafb;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .App {
    width: 100vw;
    .page-header {
      @supports (-webkit-appearance:none) {
        .os-android & {
          min-height: calc(100vh - 56px);
        }
      }
    }
    font-family: 'Be Vietnam', sans-serif;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .title {
    position: absolute;
    width: 50vw;
    top: 15vh;
    left: 22vw;
    padding: 10px;
    border: 3px black solid;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
  }
  .title:hover {
    border: 3px #222 solid;
    background-color: #222;
  }

  /* #poorreuseofcode */
  .button {
    position: absolute;
    width: 22vw;
    left: 36vw;
    padding: 10px;
    border: 3px black solid;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: 'Be Vietnam', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
    background-color: #FFF0;
  }

  .button:hover {
    cursor: pointer;
  }

  .button:active {
    background-color: #0002;
  }

  .App-link {
    color: #61dafb;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .App {
    width: 100vw;
    height: 99vh;
    font-family: 'Be Vietnam', sans-serif;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .title {
    position: absolute;
    width: 20vw;
    top: 15vh;
    left: 40vw;
    padding: 10px;
    border: 3px black solid;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: -1;
  }
  .title:hover {
    border: 3px #222 solid;
    background-color: #222;
  }

  /* #poorreuseofcode */
  .button {
    position: absolute;
    width: 22vw;
    left: 36vw;
    padding: 10px;
    border: 3px black solid;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: 'Be Vietnam', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
    background-color: #FFF0;
  }

  .button:hover {
    cursor: pointer;
  }

  .button:active {
    background-color: #0002;
  }

  .App-link {
    color: #61dafb;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}