body {
	/*overflow-y:hidden;*/
	overflow-x:hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Landscape {
	z-index: 10;
	background-color: black;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
}

.landscape-text {
	color: white;
	text-align: center;
	width: 90vw;
	margin-left: 5vw;
	margin-top: 15vh;
	font-size: 6vw;
	font-weight: bold;
}

.landscape-icon {
  -webkit-animation: landscape-icon-spin infinite 20s linear;
          animation: landscape-icon-spin infinite 20s linear;
  width: 20vw;
  height: 20vw;
  position: relative;
  top: 7vw;
  left: 40vw;
}

@-webkit-keyframes landscape-icon-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes landscape-icon-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.modal-screen {
		width: 100%;
		height: 100%;
		z-index: 9;
		position: fixed;
		top: 0;
	}

	.Modal {
		z-index: 10;
		position: fixed;
		top: 0;
		width: calc(100vw - 12px);
		border: 6px black solid;
		background-color: white;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(100% - 12px);
	}

	.Modal .scrollPrompter {
		width: 100%;
		height: 50px;
		background: -webkit-gradient(linear, left top, left bottom, from(#0000), to(#0003));
		background: -webkit-linear-gradient(#0000, #0003);
		background: linear-gradient(#0000, #0003);
		position: fixed;
		bottom: 0;
		pointer-events: none
	}

	.Modal .x {
		position: -webkit-sticky;
		position: sticky;
		width: 10vw;
		height: 10vw;
		left: 90%;
		top: 0;
	}

	/* TRIVIAL ON MOBILE */
	.Modal .x:hover {
		cursor: pointer;
	}

	.Modal .x:active {
		background-color: #0002;
	}


	/* CONSTRUCTION */
	.Modal .construction-intro {
		text-align: center;
		font-style: italic;
		font-size: 5vw;
		width: 90vw;
		margin: 3vw;
	}

	.Modal .construction-issues-header {
		font-weight: bold;
		font-style: italic;
		font-size: 5.5vw;
		margin: 3vw;
	}

	.Modal .construction-issue {
		font-size: 4.5vw;
		font-weight: bold;
		width: 90%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 3vw;
		margin-bottom: 10px;
	}

	/* CONTACT */
	.Modal .contact-intro {
		text-align: center;
		font-style: italic;
		font-size: 5vw;
		width: 90vw;
		margin: 3vw;
	}

	.Modal .contact-info-header {
		font-weight: bold;
		font-style: italic;
		font-size: 5.5vw;
		margin: 3vw;
	}

	.Modal .contact-info-item {
		display: inline-block;
		font-size: 4.5vw;
		font-weight: bold;
		width: 90%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 3vw;
		margin-bottom: 10px;
	}

	/* CREDITS */
	.Modal .credits-intro {
		text-align: center;
		font-style: italic;
		font-size: 5vw;
		width: 90vw;
		margin: 3vw;
	}	

	.Modal .credits-section .section-title {
		font-weight: bold;
		font-style: italic;
		font-size: 5.5vw;
		margin: 3vw;
	}

	.Modal .credits-section .line {
		width: 90%;
		border-bottom: 1px solid #CCC;
		letter-spacing: -1.5px;
		margin-left: 5%;
	}

	.Modal .credits-section .line .line-name {
		display: inline-block;
		width: 30%;
		vertical-align: top;
		font-size: 4.5vw;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.Modal .credits-section .line .line-name a:hover,
	.Modal .credits-section .line .line-name a:link,
	.Modal .credits-section .line .line-name a:active,
	.Modal .credits-section .line .line-name a:visited {
		text-decoration: none;
		cursor: pointer;
		color: #AAA;
		width: 100%;
	}	

	.Modal .credits-section .line .line-author {
		display: inline-block;
		width: 45%;
		vertical-align: top;
		font-size: 4.5vw;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.Modal .credits-section .line .line-author a:hover,
	.Modal .credits-section .line .line-author a:link,
	.Modal .credits-section .line .line-author a:active,
	.Modal .credits-section .line .line-author a:visited {
		text-decoration: none;
		cursor: pointer;
		color: #AAA;
		width: 100%;
	}	

	.Modal .credits-section .line .line-license {
		display: inline-block;
		font-size: 4.5vw;
		width: 19.5%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 3vw;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}		

	/* HOBBIES, GOALS, WORK, TRAVEL */
	.Modal .topic-centerpiece-container {
		width: 85vw;
		height: 85vw;
		margin: auto;
		border: 1px solid #CCC;
	}

	.Modal .topic-centerpiece-container img {
		width: 100%;
		height: 100%;
	}

	.Modal .topic-title {
		top: 5vh;
		font-size: 10vw;
		font-weight: bold;
		text-decoration: underline;
		text-align: center;
		margin-top: 15vh;
		margin-bottom: 15vh;
	}

	.Modal .subtopic .subtopic-centerpiece-container {
		width: 30vw;
		height: 30vw;
		margin: auto;
	}

	.Modal .subtopic .subtopic-centerpiece-container img {
		width: 100%;
		height: 100%;
	}

	.Modal .subtopic .subtopic-title {
		font-size: 7vw;
		font-weight: bold;
		font-style: italic;
		width: 100%;
		text-align: center;
		margin-bottom: 3vh;
	}

	.Modal .subtopic .detail .detail-name {
		display: inline-block;
		font-size: 4.5vw;
		font-weight: bold;
		width: 30%;
		margin-left: 5%;
		text-align: right;
		color: #AAA;
		vertical-align: top;
	}

	.Modal .subtopic .detail .detail-value {
		display: inline-block;
		font-size: 4.5vw;
		width: 45%;
		margin-left: 2%;
		color: black;
		font-weight: bold;
	}

	.Modal .subtopic .detail .detail-value a:hover,
	.Modal .subtopic .detail .detail-value a:link,
	.Modal .subtopic .detail .detail-value a:active,
	.Modal .subtopic .detail .detail-value a:visited {
		text-decoration: none;
		color: black;
		cursor: pointer;
	}

	.Modal .subtopic .subtopic-separator {
		width: 80%;
		margin: auto;
		height: 1px;
		background-color: #CCC;
		margin-top: 3vh;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.modal-screen {
		width: 100%;
		height: 100%;
		z-index: 9;
		position: fixed;
		top: 0;
	}

	.Modal {
		z-index: 10;
		position: fixed;
		top: 0;
		width: calc(100vw - 12px);
		border: 6px black solid;
		background-color: white;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(100% - 12px);
	}

	.Modal .scrollPrompter {
		width: 100%;
		height: 50px;
		background: -webkit-gradient(linear, left top, left bottom, from(#0000), to(#0003));
		background: -webkit-linear-gradient(#0000, #0003);
		background: linear-gradient(#0000, #0003);
		position: fixed;
		bottom: 0;
		pointer-events: none
	}

	.Modal .x {
		position: -webkit-sticky;
		position: sticky;
		width: 10vw;
		height: 10vw;
		left: 90%;
		top: 0;
	}

	/* TRIVIAL ON MOBILE */
	.Modal .x:hover {
		cursor: pointer;
	}

	.Modal .x:active {
		background-color: #0002;
	}


	/* CONSTRUCTION */
	.Modal .construction-intro {
		text-align: center;
		font-style: italic;
		font-size: 5vw;
		width: 90vw;
		margin: 3vw;
	}

	.Modal .construction-issues-header {
		font-weight: bold;
		font-style: italic;
		font-size: 5.5vw;
		margin: 3vw;
	}

	.Modal .construction-issue {
		font-size: 4.5vw;
		font-weight: bold;
		width: 90%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 3vw;
		margin-bottom: 10px;
	}

	/* CONTACT */
	.Modal .contact-intro {
		text-align: center;
		font-style: italic;
		font-size: 5vw;
		width: 90vw;
		margin: 3vw;
	}

	.Modal .contact-info-header {
		font-weight: bold;
		font-style: italic;
		font-size: 5.5vw;
		margin: 3vw;
	}

	.Modal .contact-info-item {
		display: inline-block;
		font-size: 4.5vw;
		font-weight: bold;
		width: 90%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 3vw;
		margin-bottom: 10px;
	}

	/* CREDITS */
	.Modal .credits-intro {
		text-align: center;
		font-style: italic;
		font-size: 5vw;
		width: 90vw;
		margin: 3vw;
	}	

	.Modal .credits-section .section-title {
		font-weight: bold;
		font-style: italic;
		font-size: 5.5vw;
		margin: 3vw;
	}

	.Modal .credits-section .line {
		width: 90%;
		border-bottom: 1px solid #CCC;
		letter-spacing: -1.5px;
		margin-left: 5%;
	}

	.Modal .credits-section .line .line-name {
		display: inline-block;
		width: 30%;
		vertical-align: top;
		font-size: 4.5vw;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.Modal .credits-section .line .line-name a:hover,
	.Modal .credits-section .line .line-name a:link,
	.Modal .credits-section .line .line-name a:active,
	.Modal .credits-section .line .line-name a:visited {
		text-decoration: none;
		cursor: pointer;
		color: #AAA;
		width: 100%;
	}	

	.Modal .credits-section .line .line-author {
		display: inline-block;
		width: 45%;
		vertical-align: top;
		font-size: 4.5vw;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.Modal .credits-section .line .line-author a:hover,
	.Modal .credits-section .line .line-author a:link,
	.Modal .credits-section .line .line-author a:active,
	.Modal .credits-section .line .line-author a:visited {
		text-decoration: none;
		cursor: pointer;
		color: #AAA;
		width: 100%;
	}	

	.Modal .credits-section .line .line-license {
		display: inline-block;
		font-size: 4.5vw;
		width: 19.5%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 3vw;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}		

	/* HOBBIES, GOALS, WORK, TRAVEL */
	.Modal .topic-centerpiece-container {
		width: 85vw;
		height: 85vw;
		margin: auto;
		border: 1px solid #CCC;
	}

	.Modal .topic-centerpiece-container img {
		width: 100%;
		height: 100%;
	}

	.Modal .topic-title {
		top: 5vh;
		font-size: 10vw;
		font-weight: bold;
		text-decoration: underline;
		text-align: center;
		margin-top: 15vh;
		margin-bottom: 15vh;
	}

	.Modal .subtopic .subtopic-centerpiece-container {
		width: 30vw;
		height: 30vw;
		margin: auto;
	}

	.Modal .subtopic .subtopic-centerpiece-container img {
		width: 100%;
		height: 100%;
	}

	.Modal .subtopic .subtopic-title {
		font-size: 7vw;
		font-weight: bold;
		font-style: italic;
		width: 100%;
		text-align: center;
		margin-bottom: 3vh;
	}

	.Modal .subtopic .detail .detail-name {
		display: inline-block;
		font-size: 4.5vw;
		font-weight: bold;
		width: 30%;
		margin-left: 5%;
		text-align: right;
		color: #AAA;
		vertical-align: top;
	}

	.Modal .subtopic .detail .detail-value {
		display: inline-block;
		font-size: 4.5vw;
		width: 45%;
		margin-left: 2%;
		color: black;
		font-weight: bold;
	}

	.Modal .subtopic .detail .detail-value a:hover,
	.Modal .subtopic .detail .detail-value a:link,
	.Modal .subtopic .detail .detail-value a:active,
	.Modal .subtopic .detail .detail-value a:visited {
		text-decoration: none;
		color: black;
		cursor: pointer;
	}

	.Modal .subtopic .subtopic-separator {
		width: 80%;
		margin: auto;
		height: 1px;
		background-color: #CCC;
		margin-top: 3vh;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.modal-screen {
		width: 100%;
		height: 100%;
		z-index: 9;
		position: fixed;
		top: 0;
		background-color: #000B;
	}

	.Modal {
		z-index: 10;
		position: fixed;
		top: 0;
		width: calc(60vw - 12px);
		border: 6px black solid;
		background-color: white;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(60% - 12px);
		margin-top: 20vh;
		margin-left: 20%;
	}

	.Modal .scrollPrompter {
		width: 60%;
		height: 50px;
		background: -webkit-gradient(linear, left top, left bottom, from(#0000), to(#0003));
		background: -webkit-linear-gradient(#0000, #0003);
		background: linear-gradient(#0000, #0003);
		position: fixed;
		bottom: 20.5vh;
		left: 20%;
		pointer-events: none;
	}

	.Modal .x {
		position: -webkit-sticky;
		position: sticky;
		width: 3vw;
		height: 3vw;
		left: 94%;
		top: 1%;
	}

	.Modal .x:hover{
		cursor: pointer;
	}

	.Modal .x:active {
		background-color: #0002;
	}

	/* CONSTRUCTION */
	.Modal .construction-intro {
		text-align: center;
		font-style: italic;
		font-size: 1.75vw;
		width: 90%;
		margin: 3%;
	}

	.Modal .construction-issues-header {
		font-weight: bold;
		font-style: italic;
		font-size: 1.85vw;
		margin: 3vw;
	}

	.Modal .construction-issue {
		font-size: 1.6vw;
		font-weight: bold;
		width: 90%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 5%;
		margin-bottom: 10px;
	}

	/* CONTACT */
	.Modal .contact-intro {
		text-align: center;
		font-style: italic;
		font-size: 1.75vw;
		width: 90%;
		margin: 3%;
	}

	.Modal .contact-info-header {
		font-weight: bold;
		font-style: italic;
		font-size: 1.85vw;
		margin: 3%;
	}

	.Modal .contact-info-item {
		display: inline-block;
		font-size: 1.6vw;
		font-weight: bold;
		width: 90%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 5%;
		margin-bottom: 10px;
	}

	/* CREDITS */
	.Modal .credits-intro {
		text-align: center;
		font-style: italic;
		font-size: 1.75vw;
		width: 90%;
		margin: 3%;
	}	

	.Modal .credits-section .section-title {
		font-weight: bold;
		font-style: italic;
		font-size: 1.85vw;
		margin: 3%;
	}

	.Modal .credits-section .line {
		width: 90%;
		border-bottom: 1px solid #CCC;
		letter-spacing: -1.5px;
		margin-left: 5%;
	}

	.Modal .credits-section .line .line-name {
		display: inline-block;
		width: 40%;
		vertical-align: top;
		font-size: 1.6vw;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.Modal .credits-section .line .line-name a:hover,
	.Modal .credits-section .line .line-name a:link,
	.Modal .credits-section .line .line-name a:active,
	.Modal .credits-section .line .line-name a:visited {
		text-decoration: none;
		cursor: pointer;
		color: #AAA;
		width: 100%;
	}	

	.Modal .credits-section .line .line-author {
		display: inline-block;
		width: 35%;
		vertical-align: top;
		font-size: 1.6vw;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.Modal .credits-section .line .line-author a:hover,
	.Modal .credits-section .line .line-author a:link,
	.Modal .credits-section .line .line-author a:active,
	.Modal .credits-section .line .line-author a:visited {
		text-decoration: none;
		cursor: pointer;
		color: #AAA;
		width: 100%;
	}	

	.Modal .credits-section .line .line-license {
		display: inline-block;
		font-size: 1.6vw;
		width: 19.5%;
		margin-left: 5%;
		color: #AAA;
		vertical-align: top;
		text-indent: 5%;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}		

	/* HOBBIES, GOALS, WORK, TRAVEL */
	.Modal .topic-centerpiece-container {
		width: 25%;
		height: 25%;
		margin: auto;
		border: 1px solid #CCC;
	}

	.Modal .topic-centerpiece-container img {
		width: 100%;
		height: 100%;
	}

	.Modal .topic-title {
		font-size: 2vw;
		font-weight: bold;
		text-decoration: underline;
		text-align: center;
		margin-top: 3%;
		margin-bottom: 10%;
	}

	.Modal .subtopic {
		width: 49.5%;
		display: inline-block;
		/*vertical-align: top;*/
	}

	.Modal .subtopic .subtopic-centerpiece-container {
		width: 44%;
		height: 44%;
		margin: auto;
		margin-top: 2.5%;
	}

	.Modal .subtopic .subtopic-centerpiece-container img {
		width: 100%;
		height: 100%;
	}

	.Modal .subtopic .subtopic-title {
		font-size: 2vw;
		font-weight: bold;
		font-style: italic;
		width: 100%;
		text-align: center;
		margin-bottom: 3%;
	}

	.Modal .subtopic .detail .detail-name {
		display: inline-block;
		font-size: 1.75vw;
		font-weight: bold;
		width: 38%;
		margin-left: 5%;
		text-align: right;
		color: #AAA;
		vertical-align: top;
	}

	.Modal .subtopic .detail .detail-value {
		display: inline-block;
		font-size: 1.75vw;
		width: 45%;
		margin-left: 2%;
		color: black;
		font-weight: bold;
	}

	.Modal .subtopic .detail .detail-value a:hover,
	.Modal .subtopic .detail .detail-value a:link,
	.Modal .subtopic .detail .detail-value a:active,
	.Modal .subtopic .detail .detail-value a:visited {
		text-decoration: none;
		color: black;
		cursor: pointer;
	}

	.Modal .subtopic .subtopic-separator {
		width: 80%;
		margin: auto;
		height: 1px;
		background-color: #CCC;
		margin-top: 3vh;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

.Game {
	width: 100%;
	height: 99%;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .App {
    width: 100vw;
    .page-header {
      @supports (-webkit-appearance:none) {
        .os-android & {
          min-height: calc(100vh - 56px);
        }
      }
    }
    font-family: 'Be Vietnam', sans-serif;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .title {
    position: absolute;
    width: 50vw;
    top: 15vh;
    left: 22vw;
    padding: 10px;
    border: 3px black solid;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
  }
  .title:hover {
    border: 3px #222 solid;
    background-color: #222;
  }

  /* #poorreuseofcode */
  .button {
    position: absolute;
    width: 22vw;
    left: 36vw;
    padding: 10px;
    border: 3px black solid;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: 'Be Vietnam', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
    background-color: #FFF0;
  }

  .button:hover {
    cursor: pointer;
  }

  .button:active {
    background-color: #0002;
  }

  .App-link {
    color: #61dafb;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .App {
    width: 100vw;
    .page-header {
      @supports (-webkit-appearance:none) {
        .os-android & {
          min-height: calc(100vh - 56px);
        }
      }
    }
    font-family: 'Be Vietnam', sans-serif;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .title {
    position: absolute;
    width: 50vw;
    top: 15vh;
    left: 22vw;
    padding: 10px;
    border: 3px black solid;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
  }
  .title:hover {
    border: 3px #222 solid;
    background-color: #222;
  }

  /* #poorreuseofcode */
  .button {
    position: absolute;
    width: 22vw;
    left: 36vw;
    padding: 10px;
    border: 3px black solid;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: 'Be Vietnam', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
    background-color: #FFF0;
  }

  .button:hover {
    cursor: pointer;
  }

  .button:active {
    background-color: #0002;
  }

  .App-link {
    color: #61dafb;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .App {
    width: 100vw;
    height: 99vh;
    font-family: 'Be Vietnam', sans-serif;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .title {
    position: absolute;
    width: 20vw;
    top: 15vh;
    left: 40vw;
    padding: 10px;
    border: 3px black solid;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: -1;
  }
  .title:hover {
    border: 3px #222 solid;
    background-color: #222;
  }

  /* #poorreuseofcode */
  .button {
    position: absolute;
    width: 22vw;
    left: 36vw;
    padding: 10px;
    border: 3px black solid;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: 'Be Vietnam', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1;
    background-color: #FFF0;
  }

  .button:hover {
    cursor: pointer;
  }

  .button:active {
    background-color: #0002;
  }

  .App-link {
    color: #61dafb;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
